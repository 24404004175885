<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-fab-transition>
        <v-btn
          v-if="systems.length > 0"
          v-on="on"
          color="color3 color3Text--text"
          fab small
          :class="btnClass"
        >
          <v-icon>fas fa-transporter</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Show Ratings</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2 color2Text--text" @click.stop="dialog = false" fab small>
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-select
          label="Rating Systems"
          :items="systems"
          v-model="tournament.showRatings"
          chips small-chips deletable-chips multiple
          color="color3"
          item-color="color3"
          item-text="name"
          return-object
          :loading="loading"
          :disabled="loading"
          :menu-props="{closeOnContentClick: true}"
        ></v-select>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PlayerRatings from '@/classes/PlayerRatings'
import { mapGetters } from 'vuex'

export default {
  props: ['username', 'btnClass'],
  data () {
    return {
      dialog: false,
      systems: [],
      selected: [],
      loading: false,
      loaded: [],
      results: null
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    players () {
      return this.tournament.activePlayers
    },
    playerIds () {
      return this.players ? this.players.map(m => m.ppId) : []
    },
    ml () {
      return this.players && this.players.find(f => f.ppId === 12737)
    },
    mlRate () {
      return this.ml && this.ml.getRating(2).rating
    },
    mlRate2 () {
      return this.results && this.results.filter(f => f.id === 12737)
    }
  },
  methods: {
    loadOptions () {
      this.$VBL.ratings.getOptions(this.username)
        .then(r => {
          this.systems = r.data
        })
    },
    loadRating (id) {
      if (this.loaded.includes(id)) return
      this.loading = true
      this.$VBL.ratings.getPlayers(id, this.playerIds)
        .then(r => {
          r.data.forEach(m => {
            const p = this.players.find(f => f.ppId === m.id)
            if (p) {
              p.updateRatings(new PlayerRatings(m))
            }
          })
          this.results = r.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    'tournament.showRatings' (v) {
      v.forEach(x => this.loadRating(x.id))
    }
  },
  mounted () {
    this.loadOptions()
  }
}
</script>
